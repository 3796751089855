<template>
    <v-app class="fondo">
        <v-container class="grey lighten-5 principal">
            <v-row no-gutters>
                <v-col cols="12" md="3" sm="6">
                    <Navbar/>
                </v-col>
                <v-col cols="12" sm="6" md="9">
                    <v-card class="mx-auto"  v-if="statusSurvey">
                        <div v-if="!loading">
                            <div  v-if="statusIndication == 1">
                                <h2 class="px-10 py-4 grey--text text--darken-0 font-weight-regular">Primera parte de la encuesta</h2>
                                <p class="px-15 my-0 text-subtitle grey--text text--darken-0 font-weight-light">Gracias por comenzar la encuesta:</p>
                                <p class="px-15 my-0 pb-5 text-subtitle green--text text--darken-3 font-weight-light">Antes de dar clic en CONTINUAR te sugerimos lo siguiente:</p>
                                <div class="ml-3 mt-10">
                                    <v-row class="reduce-space">
                                        <v-col
                                            cols="12"
                                            md="1"
                                            sm="6"
                                            class="pl-15"
                                            >
                                            <v-row class="ma-0 pa-0">
                                                <v-icon
                                                small
                                                color="green darken-3"
                                                class="reduce-icon"
                                                >
                                                mdi-checkbox-blank-circle
                                                </v-icon>
                                                <v-icon
                                                size="8"
                                                color="green darken-3"
                                                class="ml-4 pb-6"
                                                >
                                                mdi-checkbox-blank-circle
                                                </v-icon>
                                            </v-row>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="10"
                                        sm="6"
                                        >
                                            <p class="ml-5 text-subtitle-2 grey--text text--darken-0 font-weight-light">Leer bien las preguntas</p>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="ml-3 mt-3">
                                    <v-row class="reduce-space">
                                        <v-col
                                            cols="12"
                                            md="1"
                                            sm="6"
                                            class="pl-15"
                                            >
                                            <v-row class="ma-0 pa-0">
                                                <v-icon
                                                small
                                                color="green darken-3"
                                                class="reduce-icon"
                                                >
                                                mdi-checkbox-blank-circle
                                                </v-icon>
                                                <v-icon
                                                size="8"
                                                color="green darken-3"
                                                class="ml-4 pb-6"
                                                >
                                                mdi-checkbox-blank-circle
                                                </v-icon>
                                            </v-row>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="10"
                                        sm="6"
                                        >
                                            <p class="ml-5 text-subtitle-2 grey--text text--darken-0 font-weight-light">Contestar con la mayor sinceridad</p>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="ml-3 mt-3">
                                    <v-row class="reduce-space">
                                        <v-col
                                            cols="12"
                                            md="1"
                                            sm="6"
                                            class="pl-15"
                                            >
                                            <v-row class="ma-0 pa-0">
                                                <v-icon
                                                small
                                                color="green darken-3"
                                                class="reduce-icon"
                                                >
                                                mdi-checkbox-blank-circle
                                                </v-icon>
                                                <v-icon
                                                size="8"
                                                color="green darken-3"
                                                class="ml-4 pb-6"
                                                >
                                                mdi-checkbox-blank-circle
                                                </v-icon>
                                            </v-row>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="10"
                                        sm="6"
                                        >
                                            <p class="ml-5 text-subtitle-2 grey--text text--darken-0 font-weight-light">Completa la encuesta en una sola vez</p>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="ml-3 mt-3">
                                    <v-row class="reduce-space">
                                        <v-col
                                            cols="12"
                                            md="1"
                                            sm="6"
                                            class="pl-15"
                                            >
                                            <v-row class="ma-0 pa-0">
                                                <v-icon
                                                small
                                                color="green darken-3"
                                                class="reduce-icon"
                                                >
                                                mdi-checkbox-blank-circle
                                                </v-icon>
                                                <v-icon
                                                size="8"
                                                color="green darken-3"
                                                class="ml-4 pb-6"
                                                >
                                                mdi-checkbox-blank-circle
                                                </v-icon>
                                            </v-row>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="10"
                                        sm="6"
                                        >
                                            <p class="ml-5 text-subtitle-2 grey--text text--darken-0 font-weight-light">Finalizar la encuesta</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                            <div v-else>
                                <h2 class="px-10 py-4 grey--text text--darken-0 font-weight-regular">Parte final de la encuesta</h2>
                                <p class="px-15 my-0 text-subtitle grey--text text--darken-0 font-weight-light">Gracias por completar la encuesta:</p>
                                <p class="px-15 my-0 pb-5 text-subtitle green--text text--darken-3 font-weight-light">Antes de dar clic en CONTINUAR te sugerimos lo siguiente:</p>
                                <div class="ml-3 mt-10">
                                    <v-row class="reduce-space">
                                        <v-col
                                            cols="12"
                                            md="1"
                                            sm="6"
                                            class="pl-15"
                                            >
                                            <v-row class="ma-0 pa-0">
                                                <v-icon
                                                small
                                                color="green darken-3"
                                                class="reduce-icon"
                                                >
                                                mdi-checkbox-blank-circle
                                                </v-icon>
                                                <v-icon
                                                size="8"
                                                color="green darken-3"
                                                class="ml-4 pb-6"
                                                >
                                                mdi-checkbox-blank-circle
                                                </v-icon>
                                            </v-row>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="10"
                                        sm="6"
                                        >
                                            <p class="ml-5 text-subtitle-2 grey--text text--darken-0 font-weight-light">Leer bien las preguntas</p>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="ml-3 mt-3">
                                    <v-row class="reduce-space">
                                        <v-col
                                            cols="12"
                                            md="1"
                                            sm="6"
                                            class="pl-15"
                                            >
                                            <v-row class="ma-0 pa-0">
                                                <v-icon
                                                small
                                                color="green darken-3"
                                                class="reduce-icon"
                                                >
                                                mdi-checkbox-blank-circle
                                                </v-icon>
                                                <v-icon
                                                size="8"
                                                color="green darken-3"
                                                class="ml-4 pb-6"
                                                >
                                                mdi-checkbox-blank-circle
                                                </v-icon>
                                            </v-row>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="10"
                                        sm="6"
                                        >
                                            <p class="ml-5 text-subtitle-2 grey--text text--darken-0 font-weight-light">Contestar con la mayor sinceridad</p>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="ml-3 mt-3">
                                    <v-row class="reduce-space">
                                        <v-col
                                            cols="12"
                                            md="1"
                                            sm="6"
                                            class="pl-15"
                                            >
                                            <v-row class="ma-0 pa-0">
                                                <v-icon
                                                small
                                                color="green darken-3"
                                                class="reduce-icon"
                                                >
                                                mdi-checkbox-blank-circle
                                                </v-icon>
                                                <v-icon
                                                size="8"
                                                color="green darken-3"
                                                class="ml-4 pb-6"
                                                >
                                                mdi-checkbox-blank-circle
                                                </v-icon>
                                            </v-row>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="10"
                                        sm="6"
                                        >
                                            <p class="ml-5 text-subtitle-2 grey--text text--darken-0 font-weight-light">Completa la encuesta en una sola vez</p>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="ml-3 mt-3">
                                    <v-row class="reduce-space">
                                        <v-col
                                            cols="12"
                                            md="1"
                                            sm="6"
                                            class="pl-15"
                                            >
                                            <v-row class="ma-0 pa-0">
                                                <v-icon
                                                small
                                                color="green darken-3"
                                                class="reduce-icon"
                                                >
                                                mdi-checkbox-blank-circle
                                                </v-icon>
                                                <v-icon
                                                size="8"
                                                color="green darken-3"
                                                class="ml-4 pb-6"
                                                >
                                                mdi-checkbox-blank-circle
                                                </v-icon>
                                            </v-row>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="10"
                                        sm="6"
                                        >
                                            <p class="ml-5 text-subtitle-2 grey--text text--darken-0 font-weight-light">Finalizar la encuesta</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                            <v-card-actions class="mt-1 pb-10">
                                <v-row>
                                    <v-col class="text-right">
                                        <v-btn
                                        rounded
                                        color="grey lighten-1"
                                        class="py-6 mr-2 white--text text-h6 font-weight-light"
                                        to="/inicio"
                                        >
                                        <v-icon
                                            right
                                            dark
                                            class="mr-2"
                                            x-large
                                        >
                                            mdi-chevron-left
                                        </v-icon>
                                        ATRÁS
                                        </v-btn>
                                        <v-btn
                                        rounded
                                        color="green lighten-2"
                                        class="py-6 mr-2 white--text text-h6 font-weight-light"
                                        @click="changestatusNtest()"
                                        >
                                        CONTINUAR
                                        <v-icon
                                            right
                                            dark
                                            x-large
                                        >
                                            mdi-chevron-right
                                        </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </div>
                    </v-card>

                    <v-card class="mx-auto" v-if="!statusSurvey && !loading">
                        <div class="text-md-center">
                            <h2 class="pt-15 green--text text--darken-3 font-weight-middle">¡Felicidades!</h2>
                            <p class="pt-5 text-subtitle grey--text text--darken-1 font-weight-light">Haz concluido exitósamente toda la encuesta</p>
                            <p class="py-7">
                                <v-icon color="green darken-3" class="px-5" style="vertical-align: middle">
                                    mdi-gamepad-circle
                                </v-icon> <span class=" text-h5 grey--text text--darken-1 font-weight-light1">Gracias por haber participado</span>
                                <v-icon color="green darken-3" class="px-5" style="vertical-align: middle">
                                    mdi-gamepad-circle
                                </v-icon>
                            </p>
                            <v-btn
                            rounded
                            color="green lighten-2"
                            class="py-6 mt-6 mb-7 white--text text-h6 font-weight-light"
                            to="/inicio"
                            >
                                <v-icon
                                    right
                                    dark
                                    x-large
                                    class="mr-2"
                                >
                                    mdi-chevron-left
                                </v-icon>
                                Atras
                            </v-btn>
                            
                        </div>
                    </v-card>

                    <v-col cols="12" sm="6" md="9" v-if="loading">
                        <v-card class="mx-auto">
                            <h2 class="px-10 py-4 grey--text text--darken-0 font-weight-regular">Cargando...</h2>
                        </v-card>
                    </v-col>
                    
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

                            
<script>

import {mapActions, mapGetters,mapState} from 'vuex'
import Navbar from '../components/Navbar'


export default {
    data() {
        return {
            statusSurvey: true,
            loading: true,
            statusIndication: '',
            evaluatedInfo : [],
        }
    },
    computed: {
        ...mapState(['token'])
    },
    created() {
        this.getstatusNtest();
    },
    components: {
        Navbar
    },
    methods: {
        ...mapActions(['cerrarSesion','leerToken','guardarUsuario']),
        getstatusNtest(){
            let config = {
                headers:{
                    token: this.token
                }
            }
            this.axios.get('/evaluatedInfo', config).then(response =>{
                this.evaluatedInfo = response.data
                this.axios.get(`/procesoencuestadontest/${response.data.encuestadoId}`)
                    .then(res => {
                        console.log(res.data);
                        if(res.data == false){
                            this.statusSurvey = false
                            this.loading = false;
                        }
                    })
                    .catch(e => {
                        console.log(e.response);
                    })

                //Controlar las indicaciones
                this.axios.post('/encuestadontestProcess', this.evaluatedInfo)
                    .then(res => {
                        if(res.data.test == 1){
                            console.log(res.data);
                            this.statusIndication = 1
                            this.loading = false;
                        }else{
                            this.statusIndication = 2
                            this.loading = false;
                        }
                    })
                    .catch(e => {
                    console.log(e.response);
                    })
            });

        },
        changestatusNtest(){

            this.axios.post('/encuestadontestProcess', this.evaluatedInfo)
                .then(res => {
                    if(res.data.test == 1){
                        this.$router.push({name: 'survey1'})
                    }else{
                        this.$router.push({name: 'survey2'})
                    }
                })
                .catch(e => {
                console.log(e.response);
                })
        }
    },

}
</script>
<style>
.principal {
  margin-top: 8%;
}
#app {
  background: url('../assets/fondo1.png');
  background-size: cover;
}

.reduce-space{
    margin: -43px -10px;
}

.reduce-icon{
    margin-bottom: -6px;
}
</style>