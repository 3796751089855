<template>
  <v-app>
    <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
      <v-card>
        <v-tabs show-arrows background-color="white lighten-2" icons-and-text dark grow height="0px">
          <v-tab class="pa-0">
          </v-tab>
          <v-tab-item>
            <v-card class="px-15 pt-5">
              <v-img class="my-7 align-center justify-center mx-auto" width="250px" src="/survey.png"></v-img>
              <p class="text-md-center ma-0 grey--text text--darken-1">Bienvenido a Surveys, el espacio donde puedes</p>
              <p class="text-md-center ma-0 green--text text--darken-3">responder tus encuestas de forma rápida y sencilla</p>
              <v-card-text>
                <v-form @submit.prevent="login" ref="formLogin" lazy-validation>
                  <v-row>
                    <v-col cols="12" class="px-15">
                      <v-text-field @keydown.space.prevent label="Código" name="Codigo"
                        prepend-icon="mdi-account-circle-outline" type="text" :rules="inputRulesUsuarioLogin"
                        v-model.trim="usuario.user" required></v-text-field>
                    </v-col>
                    <v-col cols="12" class="px-15">
                      <v-text-field @keydown.space.prevent id="password" label="Contraseña" name="Contraseña"
                        v-model.trim="usuario.pass" prepend-icon="mdi-lock-outline" type="password"
                        :rules="[rulesPassword.required, rulesPassword.min]"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-alert :value="alert" v-model="alert" dismissible :color=mensaje.color border="left" elevation="2"
                        colored-border icon="mdi-information">{{ mensaje.texto }}</v-alert>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="6" xsm="12">
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex mr-5" cols="12" sm="3" xsm="12" align-end>
                      <v-btn color="green darken-3" class="py-6 mr-2 white--text text-h6 font-weight-light" x-large
                        outlined type="submit">
                        Ingresar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-dialog>
  </v-app>
</template>


<script>

import { mapActions } from 'vuex'

export default {

  data() {
    return {
      usuario: {
        user: '',
        pass: ''
      },
      mensaje: { color: '', texto: '' },
      alert: false,
      dialog: true,
      inputRulesUsuarioLogin: [
        v => !!v || 'El código es requerido'
      ],
      rulesPassword: {
        required: value => !!value || "La contraseña es requerido.",
        min: v => (v && v.length == 10) || "Tiene que tener 10 caracteres"
      }
    }
  },
  props: {
    source: String
  },
  methods: {
    ...mapActions(['guardarUsuario']),
    login() {
      if (this.$refs.formLogin.validate()) {
        this.axios.post('/loginencuestado', this.usuario)
          .then(res => {
            this.mensaje = ''
            const token = res.data.token;
            this.guardarUsuario(token);
            this.$router.push({ name: 'inicio' })
          })
          .catch(e => {
            // console.log(e.response);
            this.alert = true;
            this.mensaje.color = 'error'
            this.mensaje.texto = e.response.data.mensaje
            console.log(e.response.data.mensaje);
          })
      }
    },
    supertrim(value) {
      this.$el.value = value.replace(/\s/g, '');
    }
  },
}
</script>
<style>
#app {
  background: url('../assets/fondo1.png');
  background-size: cover;
}
</style>